<template>
  <tj-dialog
    v-model="visible"
    :title="title"
    @close="onClose"
  >
    <el-form ref="formRef" label-width="auto" :model="form" :rules="rules">
      <el-form-item label="父级：">
        <el-select v-model="form.parent_id" filterable class="wf">
          <el-option
            v-for="item in menuList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="菜单名称：" prop="title">
        <el-input v-model.trim="form.title"></el-input>
      </el-form-item>
      <el-form-item label="Name-Key：" prop="name">
        <el-input v-model.trim="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Path路径：" prop="path">
        <el-input v-model.trim="form.path"></el-input>
      </el-form-item>
      <!-- <el-form-item label="图标：">
        <el-input v-model.trim="form.icon"></el-input>
      </el-form-item> -->
      <el-form-item label="组件：" prop="component">
        <el-input v-model.trim="form.component"></el-input>
      </el-form-item>
      <el-form-item label="内部页：">
        <el-radio-group v-model="form.hidden">
          <el-radio-button :label="1">是</el-radio-button>
          <el-radio-button :label="0">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="排序：">
        <el-input-number v-model="form.sort" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="状态：">
        <el-radio-group v-model="form.state">
          <el-radio-button :label="1">显示</el-radio-button>
          <el-radio-button :label="0">隐藏</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue';
import { concat, map } from 'lodash';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';

const emit = defineEmits(['submit']);

const defaultMenuInfo = {
  id: 0,
  parent_id: 0,
  title: '',
  name: '',
  path: '',
  icon: '',
  component: '',
  hidden: 0,
  sort: 0,
  state: 1,
};

const form = ref({ ...defaultMenuInfo });
const formRef = ref();
const menuList = ref([]);

const rules = {
  title: { required: true, message: '菜单名称不能为空' },
  name: { required: true, message: 'Name-Key不能为空' },
  path: { required: true, message: 'Path路径不能为空' },
  component: { required: true, message: '组件不能为空' },
};

const title = computed(() => {
  if (form.value.id) {
    return '编辑菜单';
  }
  return '添加菜单';
});

function getMenuList() {
  request.get('/admin/menu/list').then((data) => {
    menuList.value = concat({
      label: '无',
      value: 0,
    }, map(data, (menu) => ({
      label: menu.title,
      value: menu.id,
    })));
  });
}

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((dialogData) => {
  getMenuList();
  if (dialogData) {
    form.value = {
      ...defaultMenuInfo,
      ...dialogData,
    };
  } else {
    form.value = { ...defaultMenuInfo };
  }
  nextTick(() => {
    formRef.value.clearValidate();
  });
});
function onClose() {
  form.value = { ...defaultMenuInfo };
}

function submit() {
  formRef.value.validate((valid) => {
    if (valid) {
      if (form.value.id === 0) {
        request.post('/admin/menu/store', form.value).then(() => {
          close();
          emit('submit');
        });
      } else {
        request.post(`/admin/menu/update/${form.value.id}`, form.value).then(() => {
          close();
          emit('submit');
        });
      }
    }
  });
}

defineExpose({
  open,
});
</script>
