<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-header height="auto">
        <div class="fr">
          <el-button
            type="primary"
            :icon="CirclePlus"
            @click="editMenu()"
          >添加菜单</el-button>
        </div>
      </el-header>
      <el-main>
        <el-table
          default-expand-all
          row-key="id"
          :data="tableData"
          :tree-props="treeProps"
        >
          <el-table-column label="菜单" prop="title"></el-table-column>
          <el-table-column label="Name-Key" prop="name"></el-table-column>
          <el-table-column label="Path路径" prop="path"></el-table-column>
          <!-- <el-table-column label="图标" prop="icon"></el-table-column> -->
          <el-table-column label="组件" prop="component"></el-table-column>
          <el-table-column label="内部页" align="center" width="60">
            <template #default="{ row }">
              <template v-if="row.hidden === 1">是</template>
              <template v-if="row.hidden === 0">否</template>
            </template>
          </el-table-column>
          <el-table-column label="排序" prop="sort" align="right" width="54"></el-table-column>
          <el-table-column label="状态" align="center" width="58">
            <template #default="{ row }">
              <el-tag v-if="row.state === 0" type="info">隐藏</el-tag>
              <el-tag v-if="row.state === 1" type="success">显示</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template #default="{ row }">
              <el-button
                type="text"
                :icon="Edit"
                @click="editMenu(row)"
              >编辑</el-button>
              <el-button
                type="text"
                class="color-danger"
                :icon="Delete"
                @click="remove(row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
  </div>
  <EditDialog ref="editDialog" @submit="getList"></EditDialog>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { ElMessageBox } from 'element-plus';
import { CirclePlus, Edit, Delete } from '@element-plus/icons-vue';
import request from '@/hooks/request';
import EditDialog from './edit.vue';

const tableData = ref([]);
const editDialog = ref();

const treeProps = {
  children: 'menu_child',
  hasChildren: 'hasChildren',
};

function getList() {
  request.get('/admin/menu/list').then((data) => {
    tableData.value = data;
  });
}

function editMenu(menu) {
  editDialog.value.open(menu);
}

function remove(menu) {
  ElMessageBox.confirm(`删除菜单：${menu.title}，是否继续？`, '提示', {
    type: 'warning',
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(() => {
    request.post(`/admin/menu/delete/${menu.id}`).then(() => {
      getList();
    });
  });
}

onMounted(() => {
  getList();
});
</script>
